import { useEffect, useState } from 'react';
import axios from 'axios';

import { Badge } from './useBadges.ts';
import { getAutoUpdatedMap } from './getAutoUpdatedMap.ts';

import { LEGACY_BADGES_BY_EMAIL } from '../utils/legacy-badges.ts';
import { JOURNEYS, isJourneyCompleted } from '../utils/journeys.ts';

/** Final Profile shape:
 * - `submittedBadgeIDs` is what the user originally submitted.
 * - `badgesIDs` is the final set of badges (after auto-claim & restricted rules).
 */
export interface Profile {
  email: string;
  name: string;
  lastUpdated: Date;
  submittedBadgeIDs: string[]; // The raw user-submitted badges
  badgesIDs: string[]; // The final badges after auto-updates
  sortScore: number; // Summation of badge rarities + journey completions
  journeyEmojis: string[]; // Which journeys they completed
}

// URL to your Google Sheet data in TSV format
const newResponsesUrl =
  'https://docs.google.com/spreadsheets/d/1njnU57hDxU9iEWn_W24z4reciaKkpdWqPIYahBbwbs8/export?format=tsv&id=1njnU57hDxU9iEWn_W24z4reciaKkpdWqPIYahBbwbs8&gid=1281334660';

/**
 * Loads + merges profile data from LEGACY_BADGES_BY_EMAIL and Google Sheets,
 * calculates journey completion (sortScore, emojis), then runs getAutoUpdatedMap
 * so `badgesIDs` differ from `submittedBadgeIDs` if auto-claim or restrictions apply.
 */
export function useProfiles(badgesMap: Map<string, Badge>) {
  const [profilesMap, setProfilesMap] = useState<Map<string, Profile>>(new Map());

  useEffect(() => {
    if (badgesMap.size === 0 || profilesMap.size > 0) return; // Wait until badges are loaded, or terminate if already loaded

    // We'll store partial data (without sortScore/journeyEmojis) here first.
    interface PartialProfile {
      email: string;
      name: string;
      lastUpdated: Date;
      badgesIDs: string[]; // Will be filled in later
    }

    const fetchProfiles = async () => {
      const tempMap = new Map<string, PartialProfile>();

      // 1) Start with legacy data
      LEGACY_BADGES_BY_EMAIL.forEach((legacyData, email) => {
        const legacyUpdatedMap = getAutoUpdatedMap(new Map(legacyData.badgeIDs.map((id) => [id, true])), badgesMap, {
          submittedBadgeIDs: [...badgesMap.values()]
            .filter((badge) => {
              return badge.credits?.includes(email) || badge.design?.includes(email);
            })
            .map((badge) => badge.id),
          email,
        });

        tempMap.set(email, {
          email,
          name: legacyData.name || email.split('@')[0],
          lastUpdated: new Date(legacyData.lastUpdated),
          badgesIDs: [...legacyUpdatedMap.entries()].filter(([, isOn]) => isOn).map(([key]) => key),
        });
      });

      // 2) Merge/overwrite with Google Sheets
      try {
        const resp = await axios.get(newResponsesUrl);
        const lines = resp.data.split('\n');
        lines.shift(); // remove header row

        lines.forEach((line: string) => {
          const [timestamp, email, nameAndBadges] = line.trim().split('\t');
          if (!email || !nameAndBadges) return;

          const [maybeName, rawBadges] = nameAndBadges.split(',');
          if (!rawBadges) return;

          const cleaned = rawBadges.replace(/\s+/g, ''); // remove spaces

          if (cleaned.length % 4 === 0) {
            tempMap.set(email, {
              email,
              name: maybeName || email.split('@')[0],
              lastUpdated: new Date(new Date(timestamp).getTime() - new Date(0).getTimezoneOffset() * 60000),
              badgesIDs: cleaned.match(/.{1,4}/g) || [],
            });
          }

          const newMap = getAutoUpdatedMap(new Map(tempMap.get(email)?.badgesIDs.map((id) => [id, true])), badgesMap, {
            submittedBadgeIDs: [...badgesMap.values()]
              .filter((badge) => {
                return badge.credits?.includes(email) || badge.design?.includes(email);
              })
              .map((badge) => badge.id),
            email,
          });

          tempMap.set(email, {
            ...tempMap.get(email)!,
            badgesIDs: [...newMap.entries()].filter(([, isOn]) => isOn).map(([key]) => key),
          });
        });
      } catch (err) {
        console.error('Error fetching Google Sheet data:', err);
      }

      // apply sortScore and journeyEmojis
      // 3) Figure out journey completions for each profile
      const journeyPointsByEmail = new Map<string, number>();
      const journeyEmojisByEmail = new Map<string, string[]>();

      tempMap.forEach((partialProfile, email) => {
        // For each journey, check if completed
        const completedArr = JOURNEYS.map((j) => isJourneyCompleted(j, partialProfile.badgesIDs));

        // Count "journey points" (1 for common, 2 for rare, 4 for legendary, etc.)
        const points = completedArr.reduce((acc, completed, idx) => {
          if (!completed) return acc;
          const rarity = JOURNEYS[idx].rarityName;
          return acc + (rarity === 'common' ? 1 : rarity === 'rare' ? 2 : 4);
        }, 0);

        // Collect emojis for journeys that are completed
        const emojis = JOURNEYS.filter((_, i) => completedArr[i]).map((j) => j.emoji || '');

        journeyPointsByEmail.set(email, points);
        journeyEmojisByEmail.set(email, emojis);
      });

      // 4) Determine journey rarity counts (for sorting emojis)
      const journeyRarityCount = new Map<string, number>();
      JOURNEYS.forEach((journey) => {
        let count = 0;
        tempMap.forEach((p) => {
          if (isJourneyCompleted(journey, p.badgesIDs)) {
            count++;
          }
        });
        journeyRarityCount.set(journey.emoji || '', count);
      });

      // Sort emojis by ascending rarity
      const sortedJourneyEmojisByRarity = [...journeyRarityCount.entries()]
        .sort((a, b) => a[1] - b[1])
        .map(([emoji]) => emoji);

      // 5) Construct final profiles array with .badgesIDs initially = submittedBadgeIDs
      const finalProfiles = Array.from(tempMap.values()).map<Profile>((partialProfile) => {
        const { email, name, lastUpdated, badgesIDs } = partialProfile;

        // Sum up total rarityScore from the user-submitted badges
        let badgeScore = 0;
        badgesIDs.forEach((bId) => {
          const b = badgesMap.get(bId);
          if (b) badgeScore += b.rarityScore;
        });

        const journeyScore = journeyPointsByEmail.get(email) || 0;
        const totalScore = badgeScore + journeyScore;

        // Sort the user's completed journey emojis by ascending rarity
        const finalEmojis = (journeyEmojisByEmail.get(email) || []).sort(
          (a, b) => sortedJourneyEmojisByRarity.indexOf(a) - sortedJourneyEmojisByRarity.indexOf(b)
        );

        return {
          email,
          name,
          lastUpdated,
          submittedBadgeIDs: [...badgesMap.values()]
            .filter((badge) => {
              return badge.credits?.includes(email) || badge.design?.includes(email);
            })
            .map((badge) => badge.id),
          badgesIDs: badgesIDs,
          sortScore: totalScore,
          journeyEmojis: finalEmojis,
        };
      });

      finalProfiles.sort((a, b) => b.sortScore - a.sortScore);

      setProfilesMap(finalProfiles.reduce((acc, p) => acc.set(p.email, p), new Map<string, Profile>()));
    };

    fetchProfiles();
  }, [badgesMap, profilesMap.size]);

  return { profilesMap };
}
