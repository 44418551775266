interface LegacyBadge {
  name: string;
  badgeIDs: string[];
  lastUpdated: string;
}

export const LEGACY_BADGES_BY_EMAIL = new Map<string, LegacyBadge>([
  [
    'marc.pillay@talostrading.com',
    {
      name: 'Rockstar',
      badgeIDs: ['eaze', 'dr56', 'fk3y', 'rw0q', '6sj5'],
      lastUpdated: '2024-01-22T16:45:34.000Z',
    },
  ],
  [
    'georgios.efstathopoulos@talostrading.com',
    {
      name: 'georgios.efstathopoulos',
      badgeIDs: ['eaze', 'tpbo', 'dr56', 'fk3y', 'ziyg', 'rw0q', '86xv'],
      lastUpdated: '2024-01-22T18:43:14.000Z',
    },
  ],
  [
    'josh.peschko@talostrading.com',
    {
      name: 'Josh',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        '52nh',
        'x7tl',
        'ifu1',
        'gm0p',
        '21yr',
        'n26f',
        'ixey',
      ],
      lastUpdated: '2024-01-23T23:41:00.000Z',
    },
  ],
  [
    'aditya.shroff@talostrading.com',
    {
      name: 'Aditya',
      badgeIDs: ['eaze', 'jt0d', 'fk3y', '56jo', 'rtdk', 'ifu1', '3mjs'],
      lastUpdated: '2024-01-24T05:08:16.000Z',
    },
  ],
  [
    'miguel.couto@talostrading.com',
    {
      name: 'Miguel',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'rw0q',
        'rtdk',
        '52nh',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'wg99',
        'jdoi',
        'rt3p',
        'ayyc',
      ],
      lastUpdated: '2024-01-24T11:36:37.000Z',
    },
  ],
  [
    'sergiu.marin@talostrading.com',
    {
      name: 'Sergiu',
      badgeIDs: ['eaze', 'tpbo', 'fk3y', 'rw0q', 'rtdk', '52nh', 't61t', 'x7tl', 'gm0p', 'nmyu', '0lkm', 'uv79'],
      lastUpdated: '2024-01-25T13:38:09.000Z',
    },
  ],
  [
    'constantine@talostrading.com',
    {
      name: 'Conz',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'krih',
        'rw0q',
        'rtdk',
        'iuvn',
        '7y79',
        'nmyu',
        'ao3c',
        '86xv',
        '21yr',
        'n26f',
        'gu8b',
        'p12z',
        'gcr2',
        'edgc',
        'uv79',
      ],
      lastUpdated: '2024-01-25T20:01:41.000Z',
    },
  ],
  [
    'nicholas.michael@talostrading.com',
    {
      name: 'Nick',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'ziyg',
        'zprr',
        'rtdk',
        'x7tl',
        'ifu1',
        'iqzd',
        'n0w4',
        'ao3c',
        '86xv',
        '21yr',
        'ixey',
        'edgc',
      ],
      lastUpdated: '2024-01-25T20:53:34.000Z',
    },
  ],
  [
    'theodosios.andreou@talostrading.com',
    {
      name: 'Theo Andreou',
      badgeIDs: ['eaze', 'tpbo', 'dr56', 'zprr', '7y79', 'ao3c', 'n26f', '0lkm', 'ixey', 'p12z', '0gfr'],
      lastUpdated: '2024-01-26T07:17:43.000Z',
    },
  ],
  [
    'hamish.graham@talostrading.com',
    {
      name: 'Hamish',
      badgeIDs: [
        'eaze',
        'dr56',
        'fk3y',
        'rw0q',
        'rtdk',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        '62ex',
        'c3gi',
        'c9da',
        'edgc',
      ],
      lastUpdated: '2024-01-27T16:19:08.000Z',
    },
  ],
  [
    'ethan@talostrading.com',
    {
      name: 'ethan',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'fk3y',
        'ziyg',
        '56jo',
        'qpwb',
        'krih',
        'rw0q',
        't61t',
        'ifu1',
        'gm0p',
        'iqzd',
        'n0w4',
        '7y79',
        'nmyu',
        'ao3c',
        '86xv',
        '21yr',
        'n26f',
        'gu8b',
        'q6ss',
        'om4e',
        'jdoi',
        '0lkm',
        'mgju',
        'ixey',
        'p12z',
        '1a0x',
        'gcr2',
        'edgc',
        'hpai',
        'slg4',
      ],
      lastUpdated: '2024-01-28T18:32:25.000Z',
    },
  ],
  [
    'johannes.esbjornsson@talostrading.com',
    {
      name: 'Johannes Fun',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'fk3y',
        'ziyg',
        'qpwb',
        'rw0q',
        'rtdk',
        'x7tl',
        'gm0p',
        '7y79',
        '21yr',
        'n26f',
        'p12z',
      ],
      lastUpdated: '2024-01-30T15:37:23.000Z',
    },
  ],
  [
    'alfonse@talostrading.com',
    {
      name: 'Alfonse ',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'ziyg',
        '56jo',
        'krih',
        'rw0q',
        'rtdk',
        '52nh',
        't61t',
        'ifu1',
        'gm0p',
        'hl4y',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        '86xv',
        '21yr',
        'q6ss',
        '6sj5',
        '0lkm',
        '1a0x',
        'gcr2',
        'c9da',
        'edgc',
        '0gfr',
      ],
      lastUpdated: '2024-02-05T16:54:31.000Z',
    },
  ],
  [
    'audris.siow@talostrading.com',
    {
      name: 'Audz',
      badgeIDs: [
        'eaze',
        'dr56',
        'fk3y',
        'ziyg',
        '56jo',
        'zprr',
        'krih',
        'rw0q',
        'rtdk',
        'nnm0',
        't61t',
        'x7tl',
        'mxv2',
        'ifu1',
        'gm0p',
        'n0w4',
        '7y79',
        'nmyu',
        'ao3c',
        '21yr',
        '3mjs',
        'gcr2',
        'hpai',
        '0gfr',
      ],
      lastUpdated: '2024-02-15T12:21:50.000Z',
    },
  ],
  [
    'pedro.luan@talostrading.com',
    {
      name: 'Pedro Luan',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'krih',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'nmyu',
        '1tgz',
        'cxaa',
        'b4uv',
        'p12z',
        'edgc',
        '65ry',
      ],
      lastUpdated: '2024-02-29T12:15:55.000Z',
    },
  ],
  [
    'allison@talostrading.com',
    {
      name: "Allison O'Riordan",
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'ziyg',
        'rtdk',
        '52nh',
        'ifu1',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        'jjqu',
        '86xv',
        'b4uv',
        'om4e',
      ],
      lastUpdated: '2024-02-29T16:07:11.000Z',
    },
  ],
  [
    'samar.sen@talostrading.com',
    {
      name: 'Samar',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'fk3y',
        'ziyg',
        '56jo',
        'krih',
        'rw0q',
        'rtdk',
        '52nh',
        'nnm0',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'iqzd',
        'n0w4',
        'iuvn',
        '7y79',
        'ao3c',
        '1tgz',
        '9x0m',
        'htci',
        '86xv',
        '21yr',
        'q6ss',
        '6sj5',
        '0lkm',
        'mgju',
        '1a0x',
        'gcr2',
        '1ze3',
        '0gfr',
      ],
      lastUpdated: '2024-02-29T16:10:11.000Z',
    },
  ],
  [
    'shiv.patel@talostrading.com',
    {
      name: 'Shiv',
      badgeIDs: [
        'eaze',
        'tpbo',
        'ziyg',
        'krih',
        'rw0q',
        '52nh',
        'x7tl',
        'mxv2',
        'hl4y',
        'iqzd',
        'n0w4',
        '7y79',
        'nmyu',
        'ao3c',
        'gu53',
        '1tgz',
        'hoeg',
        '9x0m',
        '7ays',
        'cxaa',
        '86xv',
        'n26f',
        'ixey',
        'p12z',
        '1a0x',
        'edgc',
        'tk16',
      ],
      lastUpdated: '2024-02-29T16:11:23.000Z',
    },
  ],
  [
    'thomas.brett@talostrading.com',
    {
      name: 'Tom Brett',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        '52nh',
        'x7tl',
        'ifu1',
        'gm0p',
        'hl4y',
        'iqzd',
        'ao3c',
        'htci',
        '86xv',
        '21yr',
        'jdoi',
        'rt3p',
        'gcr2',
        'c9da',
        'ayyc',
      ],
      lastUpdated: '2024-02-29T16:12:23.000Z',
    },
  ],
  [
    'rahil.ramchandani@talostrading.com',
    {
      name: 'Rahil',
      badgeIDs: [
        'fk3y',
        'ziyg',
        'krih',
        'rw0q',
        'rtdk',
        'ifu1',
        'gm0p',
        'wg99',
        'n0w4',
        '7y79',
        'ao3c',
        'jjqu',
        'cxaa',
      ],
      lastUpdated: '2024-02-29T16:14:06.000Z',
    },
  ],
  [
    'michael.dalessio@talostrading.com',
    {
      name: 'Michael',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'fk3y',
        'ziyg',
        '56jo',
        'rw0q',
        'rtdk',
        '52nh',
        'nnm0',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'wg99',
        'hl4y',
        'iqzd',
        'n0w4',
        'iuvn',
        '7y79',
        '1tgz',
        'hoeg',
        '9x0m',
        'htci',
        'cxaa',
        'b4uv',
        '21yr',
        '0lkm',
        'c3gi',
        '1a0x',
        'gcr2',
        'c9da',
        'edgc',
        'tk16',
        '1ze3',
      ],
      lastUpdated: '2024-02-29T16:15:35.000Z',
    },
  ],
  [
    'mathias@talostrading.com',
    {
      name: 'Mathias',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'fk3y',
        'ziyg',
        'qpwb',
        'rw0q',
        't61t',
        'x7tl',
        'gm0p',
        'wg99',
        'iqzd',
        'n0w4',
        '7y79',
        'nmyu',
        'ao3c',
        'hoeg',
        'snp0',
        '86xv',
        '21yr',
        'gu8b',
        '0lkm',
        'ixey',
        'gcr2',
        'edgc',
        'uv79',
      ],
      lastUpdated: '2024-02-29T16:16:48.000Z',
    },
  ],
  [
    'alexandru.nagy@talostrading.com',
    {
      name: 'Alex N.',
      badgeIDs: [
        'eaze',
        'dr56',
        'fk3y',
        'rw0q',
        'rtdk',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'snp0',
        '62ex',
        'rt3p',
        'c3gi',
        'c9da',
      ],
      lastUpdated: '2024-02-29T16:17:40.000Z',
    },
  ],
  [
    'brian.cleary@talostrading.com',
    {
      name: 'Brian Cleary',
      badgeIDs: [
        'eaze',
        'dr56',
        '52nh',
        'ifu1',
        'iqzd',
        'n0w4',
        '7y79',
        'nmyu',
        'ao3c',
        'hoeg',
        '86xv',
        '21yr',
        'q6ss',
        'ixey',
        '1a0x',
        'gcr2',
        'tk16',
      ],
      lastUpdated: '2024-02-29T16:22:39.000Z',
    },
  ],
  [
    'libby.vanderkaay@talostrading.com',
    {
      name: 'Libby',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        'y0az',
        'x7tl',
        'ifu1',
        'gm0p',
        'n0w4',
        '7y79',
        'ao3c',
        '1tgz',
        'hoeg',
        'htci',
        'b4uv',
        '21yr',
        'ixey',
        'c3gi',
        '1a0x',
        'c9da',
      ],
      lastUpdated: '2024-02-29T21:24:12.000Z',
    },
  ],
  [
    'tim@talostrading.com',
    {
      name: 'Tim Kwok',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'ziyg',
        'rw0q',
        'rtdk',
        'x7tl',
        'nmyu',
        'ao3c',
        '9x0m',
        '86xv',
        '21yr',
        'n26f',
        'q6ss',
        '6sj5',
        '0lkm',
        'ixey',
        '1a0x',
        'gcr2',
        'edgc',
        'tk16',
        '26vr',
      ],
      lastUpdated: '2024-03-01T14:30:24.000Z',
    },
  ],
  [
    'anthony.owusu@talostrading.com',
    {
      name: 'Anthony',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'ziyg',
        'rtdk',
        't61t',
        'x7tl',
        'gm0p',
        'ao3c',
        '1tgz',
        '7ays',
        'nwqw',
        'jdoi',
        '3mjs',
      ],
      lastUpdated: '2024-03-05T12:31:20.000Z',
    },
  ],
  [
    'audrey.chen@talostrading.com',
    {
      name: 'Audrey Chen ',
      badgeIDs: [
        'tpbo',
        'fk3y',
        'ziyg',
        '56jo',
        'rw0q',
        'rtdk',
        '52nh',
        'nnm0',
        't61t',
        'x7tl',
        'gm0p',
        '7y79',
        'nmyu',
        '1tgz',
        'hoeg',
        '9x0m',
        'htci',
        'a4jq',
        '21yr',
        'om4e',
        'gcr2',
        'tk16',
        '1ze3',
        'h588',
      ],
      lastUpdated: '2024-03-14T14:47:33.000Z',
    },
  ],
  [
    'anton@talostrading.com',
    {
      name: 'Anton Katz',
      badgeIDs: [
        'tpbo',
        'jt0d',
        'fk3y',
        'ziyg',
        '56jo',
        'rw0q',
        'rtdk',
        'nnm0',
        'y0az',
        't61t',
        'x7tl',
        'gm0p',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        'htci',
        'jjqu',
        'fyzv',
        '86xv',
        '21yr',
        'gu8b',
        'q6ss',
        'om4e',
        'jdoi',
        '6sj5',
        '0lkm',
        'mgju',
        'ixey',
        'c3gi',
        '1a0x',
        'gcr2',
        'c9da',
        'edgc',
        '1ze3',
        '26vr',
        'r2v8',
        '0gfr',
        'h588',
        'slg4',
        'bxbq',
      ],
      lastUpdated: '2024-03-15T13:40:42.000Z',
    },
  ],
  [
    'karina.robles@talostrading.com',
    {
      name: 'karina.robles',
      badgeIDs: [
        'eaze',
        'tpbo',
        'ziyg',
        'rw0q',
        'rtdk',
        '52nh',
        'nnm0',
        'x7tl',
        'mxv2',
        'ifu1',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        '1tgz',
        'snp0',
        'cxaa',
        'nwqw',
        'fyzv',
        'a4jq',
        'b4uv',
        'om4e',
        'ixey',
        'gcr2',
        'c9da',
        'tk16',
        'h588',
      ],
      lastUpdated: '2024-03-19T20:06:16.000Z',
    },
  ],
  [
    'ryan.mccloskey@talostrading.com',
    {
      name: 'Ryan',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'rw0q',
        'rtdk',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'iqzd',
        'n0w4',
        'iuvn',
        '1tgz',
        '7ays',
        'jjqu',
        'b4uv',
        '62ex',
        'rt3p',
        'c3gi',
        '1a0x',
        'c9da',
        'edgc',
        '1ze3',
        'r2v8',
      ],
      lastUpdated: '2024-03-20T17:01:17.000Z',
    },
  ],
  [
    'johan.netzler@talostrading.com',
    {
      name: 'Johan',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'fk3y',
        'ziyg',
        'qpwb',
        '52nh',
        't61t',
        'x7tl',
        'gm0p',
        'n0w4',
        '1tgz',
        'hoeg',
        'nwqw',
        '86xv',
        'b4uv',
        '21yr',
        'ixey',
        'r2v8',
      ],
      lastUpdated: '2024-03-21T00:03:27.000Z',
    },
  ],
  [
    'frank.vanzegveld@talostrading.com',
    {
      name: 'Frank',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'ziyg',
        '56jo',
        'rw0q',
        'nnm0',
        't61t',
        'x7tl',
        'gm0p',
        'wg99',
        'n0w4',
        '9x0m',
        'nwqw',
        'a4jq',
        '21yr',
        'q6ss',
        'om4e',
        '6sj5',
        '0gfr',
        'h588',
      ],
      lastUpdated: '2024-03-25T15:20:17.000Z',
    },
  ],
  [
    'pamela.lee@talostrading.com',
    {
      name: 'Pamela Lee',
      badgeIDs: ['eaze', 'tpbo', 'fk3y', 'x7tl', 'n0w4', 'cxaa', 'fyzv', '86xv'],
      lastUpdated: '2024-04-04T15:42:17.000Z',
    },
  ],
  [
    'jeffrey.stokes@talostrading.com',
    {
      name: 'Jeff Stokes',
      badgeIDs: ['eaze', 'tpbo', 'dr56', 'ziyg', 'krih', 'rtdk', 'iqzd', '1tgz'],
      lastUpdated: '2024-04-11T22:30:02.000Z',
    },
  ],
  [
    'vasileia.lellou@talostrading.com',
    {
      name: 'Valia',
      badgeIDs: ['eaze', 'dr56', 'ziyg', 'zprr', 'krih', 'rtdk', 'y0az', '7y79', '9x0m', 'jjqu', '21yr', '62ex'],
      lastUpdated: '2024-04-12T15:34:18.000Z',
    },
  ],
  [
    'dan.packham@talostrading.com',
    {
      name: 'Dan Packham',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        'nnm0',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'n0w4',
        '7y79',
        'ao3c',
        '1tgz',
        'hoeg',
        'htci',
        'fyzv',
        'tnlq',
        'b4uv',
        '21yr',
        'q6ss',
        'om4e',
        '0lkm',
        '3mjs',
        'c3gi',
        '1a0x',
        'gcr2',
        'edgc',
        'tk16',
      ],
      lastUpdated: '2024-05-02T16:59:20.000Z',
    },
  ],
  [
    'eliad.hoch@talostrading.com',
    {
      name: 'Eliad Hoch',
      badgeIDs: ['eaze', 't61t', 'ifu1', 'gm0p', 'n0w4', 'nmyu', 'htci', '7ays'],
      lastUpdated: '2024-05-07T17:48:56.000Z',
    },
  ],
  [
    'scott.shaffer@talostrading.com',
    {
      name: 'Scott',
      badgeIDs: [
        'eaze',
        'tpbo',
        'ziyg',
        '56jo',
        'krih',
        'rtdk',
        'x7tl',
        'nmyu',
        'gu53',
        '1tgz',
        'htci',
        '7ays',
        '21yr',
        'p12z',
        't4lx',
      ],
      lastUpdated: '2024-05-09T14:21:56.000Z',
    },
  ],
  [
    'george.christou@talostrading.com',
    {
      name: 'George',
      badgeIDs: ['eaze', 'tpbo', 'dr56', 'fk3y', 'zprr', 'rw0q', 'iqzd', 'ao3c', '1tgz', 'hoeg', 'snp0', 'edgc'],
      lastUpdated: '2024-05-09T14:25:16.000Z',
    },
  ],
  [
    'gabor@talostrading.com',
    {
      name: 'Gabor',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'ziyg',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'iqzd',
        '7y79',
        '1tgz',
        'xray',
        '7ays',
        'cxaa',
        'nwqw',
        '86xv',
        '21yr',
        'n26f',
        'jdoi',
        'ixey',
        'p12z',
        'gcr2',
        'edgc',
        'uv79',
      ],
      lastUpdated: '2024-05-09T14:39:59.000Z',
    },
  ],
  [
    'adam@talostrading.com',
    {
      name: 'adam',
      badgeIDs: [
        'eaze',
        'tpbo',
        'ziyg',
        'rw0q',
        'rtdk',
        '52nh',
        'x7tl',
        'wg99',
        'iqzd',
        'n0w4',
        'iuvn',
        '7y79',
        'nmyu',
        'ao3c',
        'hoeg',
        '9x0m',
        'htci',
        '7ays',
        'jjqu',
        'nwqw',
        'sy7t',
        '86xv',
        'n26f',
        'jdoi',
        'p12z',
        '1a0x',
        'edgc',
        '1mew',
      ],
      lastUpdated: '2024-05-09T14:45:37.000Z',
    },
  ],
  [
    'alexandra.gardmo@talostrading.com',
    {
      name: 'Associate of Viking Vibes ',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'rw0q',
        'rtdk',
        'nnm0',
        't61t',
        'x7tl',
        'mxv2',
        'gm0p',
        'n0w4',
        '1tgz',
        '7ays',
        'jjqu',
        'fyzv',
        'a4jq',
        'dz9q',
        'zqtm',
        'b4uv',
        'om4e',
        'rt3p',
        'ixey',
        '3mjs',
        'c3gi',
        '1a0x',
        'gcr2',
        'edgc',
        '65ry',
        'tk16',
        '1ze3',
        'r2v8',
      ],
      lastUpdated: '2024-05-13T14:00:57.000Z',
    },
  ],
  [
    'taeho.kim@talostrading.com',
    {
      name: 'Taeho Kim',
      badgeIDs: ['eaze'],
      lastUpdated: '2024-05-13T15:59:51.000Z',
    },
  ],
  [
    'kat@talostrading.com',
    {
      name: 'KDM',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        '52nh',
        'nnm0',
        'x7tl',
        'mxv2',
        'ifu1',
        'gm0p',
        'hl4y',
        'iqzd',
        'n0w4',
        '7y79',
        'nmyu',
        'ao3c',
        '1tgz',
        '9x0m',
        'htci',
        'jjqu',
        'fyzv',
        '86xv',
        'b4uv',
        '21yr',
        'om4e',
        'mgju',
        '1a0x',
        'gcr2',
        'c9da',
        'edgc',
        '65ry',
        'tk16',
        'r2v8',
        't4lx',
        'h588',
        'slg4',
      ],
      lastUpdated: '2024-05-16T16:44:35.000Z',
    },
  ],
  [
    'brad@talostrading.com',
    {
      name: 'Brad',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'fk3y',
        'ziyg',
        'qpwb',
        'rw0q',
        '52nh',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'wg99',
        'hl4y',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        '1tgz',
        'hoeg',
        '9x0m',
        'cxaa',
        'tnlq',
        'sy7t',
        'dz9q',
        '86xv',
        '21yr',
        'q6ss',
        'jdoi',
        '0lkm',
        'ixey',
        'p12z',
        '1a0x',
        'gcr2',
        'c9da',
        'edgc',
        'tk16',
        'lpzy',
        'r2v8',
        't4lx',
      ],
      lastUpdated: '2024-05-16T18:17:20.000Z',
    },
  ],
  [
    'robert.zetterlund@talostrading.com',
    {
      name: 'Robert Z',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'qpwb',
        'rw0q',
        'rtdk',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'n0w4',
        'gu53',
        '1tgz',
        'hoeg',
        '7ays',
        'snp0',
        'cxaa',
        'a4jq',
        'sy7t',
        'dz9q',
        'zqtm',
        'b4uv',
        '21yr',
        'rt3p',
        'ixey',
        'p12z',
        'gcr2',
        'edgc',
        '26vr',
        'r2v8',
        '1mew',
      ],
      lastUpdated: '2024-05-30T12:23:24.000Z',
    },
  ],
  [
    'zachary.shine@talostrading.com',
    {
      name: 'Zack',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'ziyg',
        'krih',
        'rw0q',
        'x7tl',
        'ifu1',
        'gm0p',
        'iqzd',
        'n0w4',
        '7y79',
        'nmyu',
        'ao3c',
        'gu53',
        '1tgz',
        'hoeg',
        '9x0m',
        '7ays',
        'cxaa',
        'fyzv',
        'tnlq',
        'a4jq',
        'sy7t',
        '21yr',
        'p12z',
        'tk16',
        '26vr',
        'r2v8',
      ],
      lastUpdated: '2024-05-30T12:26:42.000Z',
    },
  ],
  [
    'mariani.mewengkang@talostrading.com',
    {
      name: 'mariani',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'ziyg',
        'rw0q',
        'rtdk',
        '52nh',
        'nnm0',
        'y0az',
        'x7tl',
        'mxv2',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        'jjqu',
        'fyzv',
        'a4jq',
        '86xv',
        'b4uv',
        'om4e',
        'ixey',
        'gcr2',
        'tk16',
        'ao8z',
      ],
      lastUpdated: '2024-05-31T16:04:02.000Z',
    },
  ],
  [
    'kamil@talostrading.com',
    {
      name: 'Kamil',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'fk3y',
        'ziyg',
        'rtdk',
        '52nh',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'n0w4',
        '7y79',
        'ao3c',
        '1tgz',
        'snp0',
        'nwqw',
        'a4jq',
        '86xv',
        'b4uv',
        '21yr',
        'n26f',
        'gu8b',
        'q6ss',
        'jdoi',
        '0lkm',
        'ixey',
        'p12z',
        'c9da',
        'r2v8',
        'uv79',
      ],
      lastUpdated: '2024-06-03T13:49:05.000Z',
    },
  ],
  [
    'neelabh.dixit@talostrading.com',
    {
      name: 'ND',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'fk3y',
        '56jo',
        'rw0q',
        'rtdk',
        'ifu1',
        'gm0p',
        'n0w4',
        'nmyu',
        '1tgz',
        'fyzv',
        'ixey',
        'gcr2',
        'c9da',
        '26vr',
      ],
      lastUpdated: '2024-06-08T05:06:01.000Z',
    },
  ],
  [
    'rebecca.lumsdon@talostrading.com',
    {
      name: 'Rebecca',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        'x7tl',
        'gm0p',
        'hl4y',
        'iqzd',
        'n0w4',
        '1tgz',
        'htci',
        '7ays',
        'jjqu',
        'nwqw',
        'tnlq',
        '86xv',
        'ixey',
        'p12z',
        'gcr2',
        '65ry',
        'tk16',
        'r2v8',
        't4lx',
      ],
      lastUpdated: '2024-06-10T21:09:22.000Z',
    },
  ],
  [
    'tobias.brandt@talostrading.com',
    {
      name: 'Tobias',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        '56jo',
        'zprr',
        'rw0q',
        '52nh',
        'x7tl',
        'iqzd',
        '1tgz',
        'hoeg',
        'nwqw',
        'fyzv',
        'sy7t',
        '86xv',
        'b4uv',
        '21yr',
        'n26f',
        'jdoi',
        'p12z',
        'gcr2',
        '1ze3',
      ],
      lastUpdated: '2024-06-24T08:56:06.000Z',
    },
  ],
  [
    'matty@talostrading.com',
    {
      name: 'Matty',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        't61t',
        'x7tl',
        'ifu1',
        'hl4y',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        'gu53',
        '1tgz',
        'hoeg',
        'xray',
        '7ays',
        'nwqw',
        'fyzv',
        'a4jq',
        '86xv',
        '21yr',
        'n26f',
        'gu8b',
        'q6ss',
        'jdoi',
        '0lkm',
        'ixey',
        'p12z',
        '1a0x',
        'gcr2',
        'r2v8',
        'uv79',
        'slg4',
      ],
      lastUpdated: '2024-06-24T14:53:46.000Z',
    },
  ],
  [
    'frank@talostrading.com',
    {
      name: 'frank',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'ziyg',
        'qpwb',
        '52nh',
        'x7tl',
        'ifu1',
        'hl4y',
        'n0w4',
        '7y79',
        'nmyu',
        'ao3c',
        'gu53',
        'xray',
        'snp0',
        'nwqw',
        'a4jq',
        'sy7t',
        'dz9q',
        '86xv',
        '21yr',
        'jdoi',
        '0lkm',
        'ixey',
        'p12z',
        '1a0x',
        'gcr2',
        'edgc',
        'r2v8',
        'ayyc',
      ],
      lastUpdated: '2024-06-27T17:49:08.000Z',
    },
  ],
  [
    'zheng.geng.phan@talostrading.com',
    {
      name: 'Z',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'ziyg',
        '56jo',
        'zprr',
        'qpwb',
        'krih',
        'rw0q',
        'rtdk',
        '52nh',
        'nnm0',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        'gu53',
        '1tgz',
        'htci',
        'snp0',
        'fyzv',
        'tnlq',
        'sy7t',
        'zqtm',
        '86xv',
        'b4uv',
        '21yr',
        'q6ss',
        'ixey',
        'p12z',
        '1a0x',
        'gcr2',
        'c9da',
        '1mew',
        't4lx',
        'hpai',
        '4xjt',
      ],
      lastUpdated: '2024-07-02T22:09:00.000Z',
    },
  ],
  [
    'barry.may@talostrading.com',
    {
      name: 'Barry',
      badgeIDs: [
        'eaze',
        'ziyg',
        'x7tl',
        'ifu1',
        'iqzd',
        'gu53',
        'htci',
        '7ays',
        'jjqu',
        'snp0',
        'cxaa',
        'ixey',
        'c9da',
        'edgc',
        't4lx',
      ],
      lastUpdated: '2024-07-12T01:48:44.000Z',
    },
  ],
  [
    'labdhi.jain@talostrading.com',
    {
      name: 'Labdhi Jain',
      badgeIDs: ['eaze', 'jt0d', 'ziyg', 'y0az', 'hoeg', 'jjqu'],
      lastUpdated: '2024-07-24T21:25:11.000Z',
    },
  ],
  [
    'olof@talostrading.com',
    {
      name: 'Olof',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'ziyg',
        'qpwb',
        'krih',
        'rw0q',
        'rtdk',
        'x7tl',
        'ifu1',
        'iqzd',
        'ao3c',
        '1tgz',
        'hoeg',
        '7ays',
        'cxaa',
        'tnlq',
        'a4jq',
        'sy7t',
        'dz9q',
        'zqtm',
        '86xv',
        'b4uv',
        '21yr',
        'n26f',
        'q6ss',
        'jdoi',
        '0lkm',
        'ixey',
        'p12z',
        'gcr2',
        'edgc',
        'tk16',
        '26vr',
        'r2v8',
        'uv79',
      ],
      lastUpdated: '2024-08-15T20:43:36.000Z',
    },
  ],
  [
    'bharat@talostrading.com',
    {
      name: 'bharat',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'ziyg',
        'rw0q',
        'nnm0',
        'x7tl',
        'gm0p',
        'n0w4',
        '1tgz',
        'hoeg',
        '9x0m',
        '86xv',
        'gu8b',
        'g4xq',
        'uv79',
        'ayyc',
      ],
      lastUpdated: '2024-08-28T20:44:02.000Z',
    },
  ],
  [
    'brian.teh@talostrading.com',
    {
      name: 'BT',
      badgeIDs: ['tpbo', 'fk3y', '56jo', 'rw0q', 'rtdk', 'fyzv', 'sy7t', 'ixey'],
      lastUpdated: '2024-08-30T08:07:10.000Z',
    },
  ],
  [
    'anton.deshchenko@talostrading.com',
    {
      name: 'Anton D. ',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        'nnm0',
        'y0az',
        'x7tl',
        'ifu1',
        'gm0p',
        'hl4y',
        'iqzd',
        '7y79',
        'ao3c',
        'gu53',
        '1tgz',
        'htci',
        '7ays',
        'jjqu',
        'fyzv',
        'a4jq',
        'sy7t',
        'b4uv',
        '21yr',
        'rt3p',
        'ixey',
        'gcr2',
        'c9da',
        'edgc',
        '65ry',
        '26vr',
        '1mew',
        't4lx',
        'ao8z',
      ],
      lastUpdated: '2024-09-23T15:49:17.000Z',
    },
  ],
  [
    'thierry.hue@talostrading.com',
    {
      name: 'Thierry',
      badgeIDs: ['eaze', 'fk3y', 'rtdk', 't61t', 'x7tl', 'ifu1', 'gm0p', 'n0w4', 'snp0', 'fyzv', 'sy7t', 'c9da'],
      lastUpdated: '2024-10-01T11:43:13.000Z',
    },
  ],
  [
    'peter@talostrading.com',
    {
      name: 'Peter Shi',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        'x7tl',
        'hl4y',
        'n0w4',
        '7y79',
        'nmyu',
        'ao3c',
        'gu53',
        '1tgz',
        'xray',
        '7ays',
        'fyzv',
        'sy7t',
        '86xv',
        '21yr',
        'n26f',
        'ixey',
        'p12z',
        '1a0x',
        'gcr2',
        'edgc',
        '26vr',
        '1mew',
        'uv79',
        'slg4',
        'ibdm',
      ],
      lastUpdated: '2024-10-13T22:15:19.000Z',
    },
  ],
  [
    'teresa.garcia@talostrading.com',
    {
      name: 'Tgar',
      badgeIDs: [
        'eaze',
        'tpbo',
        'ziyg',
        'krih',
        'rw0q',
        'rtdk',
        '52nh',
        'x7tl',
        'iqzd',
        '7ays',
        'jjqu',
        'snp0',
        'nwqw',
        'b4uv',
        '65ry',
        't4lx',
        'ibdm',
        'p5m0',
      ],
      lastUpdated: '2024-10-14T16:01:02.000Z',
    },
  ],
  [
    'mateusz.pulka@talostrading.com',
    {
      name: 'mateusz.pulka',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'ziyg',
        'zprr',
        'rtdk',
        'ao3c',
        'nwqw',
        '86xv',
        '21yr',
        '0lkm',
        'ixey',
        'r2v8',
      ],
      lastUpdated: '2024-10-22T13:06:48.000Z',
    },
  ],
  [
    'andrew.theodosiou@talostrading.com',
    {
      name: 'Andrew T',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'ziyg',
        'zprr',
        'rw0q',
        'rtdk',
        '52nh',
        'nnm0',
        't61t',
        'x7tl',
        'mxv2',
        'ifu1',
        'gm0p',
        'iqzd',
        'n0w4',
        'iuvn',
        '7y79',
        '1tgz',
        '9x0m',
        'htci',
        '7ays',
        'jjqu',
        'cxaa',
        'fyzv',
        'lvrp',
        'l1ys',
        '21yr',
        '6sj5',
        'rt3p',
        'ixey',
        '3mjs',
        'c3gi',
        'gcr2',
        'c9da',
        '65ry',
        '1ze3',
        '0gfr',
        'p5m0',
      ],
      lastUpdated: '2024-10-22T18:46:54.000Z',
    },
  ],
  [
    'rick.agrella@talostrading.com',
    {
      name: 'Rick Agrella',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'ziyg',
        'zprr',
        'rtdk',
        '52nh',
        'x7tl',
        'ifu1',
        'gm0p',
        'iqzd',
        'n0w4',
        '1tgz',
        'snp0',
        'nwqw',
        'sy7t',
        '86xv',
        'b4uv',
        '21yr',
        'ayyc',
      ],
      lastUpdated: '2024-10-23T15:04:59.000Z',
    },
  ],
  [
    'elizabeth@talostrading.com',
    {
      name: 'Dethy',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'ziyg',
        '56jo',
        'rw0q',
        'rtdk',
        '52nh',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'hl4y',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        'gu53',
        '1tgz',
        'hoeg',
        '9x0m',
        '7ays',
        'snp0',
        'cxaa',
        'nwqw',
        'sy7t',
        '86xv',
        '21yr',
        'ixey',
        '1a0x',
        'gcr2',
        'c9da',
        'edgc',
        'tk16',
        'lpzy',
        '1mew',
        '8nll',
        'ayyc',
        '4xjt',
        'ibdm',
      ],
      lastUpdated: '2024-10-24T02:43:48.000Z',
    },
  ],
  [
    'daniel.lezama@talostrading.com',
    {
      name: 'Dan Lezama',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'fk3y',
        'ziyg',
        'zprr',
        'rw0q',
        'rtdk',
        '52nh',
        'nnm0',
        'y0az',
        'x7tl',
        'ifu1',
        'gm0p',
        'n0w4',
        '7y79',
        'nmyu',
        'ao3c',
        'jjqu',
        'fyzv',
        'tnlq',
        'a4jq',
        '86xv',
        'b4uv',
        '21yr',
        'q6ss',
        'rt3p',
        'ixey',
        'c9da',
        'edgc',
        'tk16',
        'ao8z',
        'ayyc',
        'ibdm',
      ],
      lastUpdated: '2024-10-29T21:35:02.000Z',
    },
  ],
  [
    'kel.ezekwe@talostrading.com',
    {
      name: 'Kel ',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        'y0az',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        '1tgz',
        '9x0m',
        'htci',
        '7ays',
        'jjqu',
        'snp0',
        'cxaa',
        'fyzv',
        'tnlq',
        'a4jq',
        'sy7t',
        'b4uv',
        '21yr',
        'rt3p',
        '3mjs',
        'c3gi',
        'c9da',
        'edgc',
        '65ry',
        '1ze3',
        '26vr',
        'r2v8',
        't4lx',
        'ao8z',
        'ayyc',
      ],
      lastUpdated: '2024-10-31T14:37:34.000Z',
    },
  ],
  [
    'emanuele.melis@talostrading.com',
    {
      name: 'Emanuele',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        '52nh',
        't61t',
        'x7tl',
        'mxv2',
        'ifu1',
        'gm0p',
        'n0w4',
        'iuvn',
        '7y79',
        'nmyu',
        'ao3c',
        '1tgz',
        'hoeg',
        '9x0m',
        'htci',
        'snp0',
        'cxaa',
        'fyzv',
        'a4jq',
        'sy7t',
        'pq4t',
        '86xv',
        'b4uv',
        '21yr',
        'jdoi',
        'ixey',
        'p12z',
        '1a0x',
        'gcr2',
        'c9da',
        '1ze3',
        '1mew',
        't4lx',
        '8nll',
        'mh96',
      ],
      lastUpdated: '2024-11-20T20:23:56.000Z',
    },
  ],
  [
    'peter.jastreboff@talostrading.com',
    {
      name: 'Pete',
      badgeIDs: [
        'eaze',
        'tpbo',
        'fk3y',
        'ziyg',
        '56jo',
        'qpwb',
        'krih',
        'rw0q',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        '1tgz',
        'hoeg',
        'cxaa',
        'nwqw',
        'a4jq',
        'sy7t',
        '86xv',
        'b4uv',
        '21yr',
        'jdoi',
        'ixey',
        '1a0x',
        'gcr2',
        'c9da',
        '1mew',
        'hpai',
        '0gfr',
        'ayyc',
        'ibdm',
      ],
      lastUpdated: '2024-11-25T11:56:34.000Z',
    },
  ],
  [
    'daniel@talostrading.com',
    {
      name: 'Daniel',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'fk3y',
        'ziyg',
        'qpwb',
        'krih',
        'rw0q',
        'rtdk',
        'nnm0',
        't61t',
        'x7tl',
        'gm0p',
        'hl4y',
        'iqzd',
        'n0w4',
        'iuvn',
        '7y79',
        'nmyu',
        'ao3c',
        '1tgz',
        'hoeg',
        '9x0m',
        'xray',
        'htci',
        'snp0',
        'cxaa',
        'tnlq',
        'a4jq',
        'sy7t',
        'dz9q',
        'zqtm',
        '86xv',
        'b4uv',
        '21yr',
        'n26f',
        'gu8b',
        'q6ss',
        'jdoi',
        '0lkm',
        'ixey',
        'p12z',
        '1a0x',
        'gcr2',
        'edgc',
        '65ry',
        '26vr',
        'r2v8',
        'g4xq',
        '8nll',
        'hpai',
        'uv79',
      ],
      lastUpdated: '2024-12-06T11:10:31.000Z',
    },
  ],
  [
    'thomas.kennedy@talostrading.com',
    {
      name: 'TOMK',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'iqzd',
        'n0w4',
        '7y79',
        '1tgz',
        'htci',
        'jjqu',
        'cxaa',
        'lvrp',
        'b4uv',
        '21yr',
        '62ex',
        '6sj5',
        'rt3p',
        'gcr2',
        'c9da',
        'edgc',
        'lpzy',
        'ao8z',
        'j4l9',
      ],
      lastUpdated: '2024-12-17T17:17:27.000Z',
    },
  ],
  [
    'ralfi@talostrading.com',
    {
      name: '🏰 Baron of the Badge Realm',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'ziyg',
        'qpwb',
        'rw0q',
        'rtdk',
        '52nh',
        'nnm0',
        'y0az',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'hl4y',
        'iqzd',
        'n0w4',
        'iuvn',
        '7y79',
        'nmyu',
        'gu53',
        '1tgz',
        'hoeg',
        '9x0m',
        'xray',
        'htci',
        '7ays',
        'jjqu',
        'snp0',
        'cxaa',
        'nwqw',
        'fyzv',
        'a4jq',
        'sy7t',
        'zqtm',
        'lvrp',
        'pq4t',
        'l1ys',
        '86xv',
        'b4uv',
        '21yr',
        'gu8b',
        'q6ss',
        'om4e',
        'jdoi',
        'rt3p',
        'ixey',
        'c3gi',
        'p12z',
        'gcr2',
        'c9da',
        'edgc',
        '65ry',
        'tk16',
        '26vr',
        'r2v8',
        '1mew',
        'g4xq',
        'uv79',
        'uwpa',
        'ibdm',
      ],
      lastUpdated: '2024-12-17T21:18:05.000Z',
    },
  ],
  [
    'rob@talostrading.com',
    {
      name: 'Rob R',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'ziyg',
        'rw0q',
        'rtdk',
        '52nh',
        'x7tl',
        'mxv2',
        'ifu1',
        'iqzd',
        'n0w4',
        '7y79',
        'nmyu',
        'ao3c',
        'gu53',
        '1tgz',
        'hoeg',
        'htci',
        'nwqw',
        'sy7t',
        '86xv',
        'b4uv',
        'n26f',
        'q6ss',
        'jdoi',
        'p12z',
        '1a0x',
        'gcr2',
        'c9da',
        'edgc',
        'tk16',
        '1mew',
        't4lx',
        'uv79',
      ],
      lastUpdated: '2024-12-19T01:50:07.000Z',
    },
  ],
  [
    'ant@talostrading.com',
    {
      name: 'Ant',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'fk3y',
        'ziyg',
        '56jo',
        'qpwb',
        'rw0q',
        'rtdk',
        '52nh',
        'y0az',
        't61t',
        'x7tl',
        'mxv2',
        'ifu1',
        'gm0p',
        'iqzd',
        'n0w4',
        'iuvn',
        '7y79',
        'nmyu',
        'ao3c',
        '1tgz',
        'hoeg',
        '9x0m',
        'htci',
        '7ays',
        'jjqu',
        'cxaa',
        'nwqw',
        'fyzv',
        'tnlq',
        'a4jq',
        'zqtm',
        'lvrp',
        'pq4t',
        '86xv',
        'b4uv',
        '21yr',
        'q6ss',
        '0lkm',
        'rt3p',
        'ixey',
        '1a0x',
        'gcr2',
        'c9da',
        'edgc',
        '65ry',
        'tk16',
        '1ze3',
        'r2v8',
        't4lx',
        '8nll',
        '1m01',
        'hpai',
        'mh96',
        'ibdm',
      ],
      lastUpdated: '2024-12-19T03:22:25.000Z',
    },
  ],
  [
    'nisarg.shah@talostrading.com',
    {
      name: 'Nisarg Shah',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        '52nh',
        'nnm0',
        't61t',
        'x7tl',
        'mxv2',
        'ifu1',
        'gm0p',
        'hl4y',
        'n0w4',
        '7y79',
        '1tgz',
        '9x0m',
        'htci',
        '7ays',
        'jjqu',
        'cxaa',
        'nwqw',
        '86xv',
        'b4uv',
        '21yr',
        'q6ss',
        '6sj5',
        'rt3p',
        'ixey',
        '1a0x',
        'gcr2',
        'c9da',
        'edgc',
        'tk16',
        'lpzy',
        'r2v8',
        't4lx',
      ],
      lastUpdated: '2024-12-19T04:32:04.000Z',
    },
  ],
  [
    'kyle.wiltshire@talostrading.com',
    {
      name: 'OG Kyle',
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'fk3y',
        'ziyg',
        'krih',
        'rw0q',
        'rtdk',
        '52nh',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'wg99',
        'iqzd',
        'n0w4',
        'iuvn',
        '7y79',
        'nmyu',
        'ao3c',
        '1tgz',
        'hoeg',
        'htci',
        '7ays',
        'jjqu',
        'nwqw',
        'fyzv',
        'tnlq',
        'a4jq',
        '86xv',
        'b4uv',
        '21yr',
        'n26f',
        'q6ss',
        '0lkm',
        'mgju',
        'rt3p',
        'ixey',
        '3mjs',
        'c3gi',
        '1a0x',
        'gcr2',
        'c9da',
        'edgc',
        '65ry',
        'tk16',
        '1ze3',
        '26vr',
        'r2v8',
        'ao8z',
        '8nll',
        'slg4',
      ],
      lastUpdated: '2024-12-19T10:34:28.000Z',
    },
  ],
  [
    'kate.duggan@talostrading.com',
    {
      name: 'Kate Duggan',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'fk3y',
        'ziyg',
        'rw0q',
        'rtdk',
        '52nh',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'hl4y',
        'iqzd',
        'nmyu',
        'ao3c',
        'gu53',
        '1tgz',
        'hoeg',
        'htci',
        'jjqu',
        'cxaa',
        'nwqw',
        'sy7t',
        'pq4t',
        '86xv',
        'b4uv',
        '21yr',
        'c9da',
        'edgc',
        '65ry',
        '1mew',
        '99lz',
        '4xjt',
        'j4l9',
      ],
      lastUpdated: '2024-12-19T12:52:31.000Z',
    },
  ],
  [
    'damien.schoof@talostrading.com',
    {
      name: 'Damien',
      badgeIDs: [
        'eaze',
        'tpbo',
        'ziyg',
        'qpwb',
        'rw0q',
        'rtdk',
        '52nh',
        'x7tl',
        'ifu1',
        'iqzd',
        'nmyu',
        '1tgz',
        'hoeg',
        'htci',
        'snp0',
        'cxaa',
        'nwqw',
        'a4jq',
        'sy7t',
        'dz9q',
        '86xv',
        'b4uv',
        '21yr',
        'jdoi',
        'ixey',
        'p12z',
        'gcr2',
        'c9da',
        't4lx',
        'ibdm',
      ],
      lastUpdated: '2024-12-19T14:33:18.000Z',
    },
  ],
  [
    'roland.jarquio@talostrading.com',
    {
      name: "Roland's Wall",
      badgeIDs: [
        'eaze',
        'tpbo',
        'dr56',
        'fk3y',
        'ziyg',
        'krih',
        'rw0q',
        'rtdk',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        'hl4y',
        'n0w4',
        'iuvn',
        '7y79',
        'ao3c',
        '1tgz',
        '9x0m',
        'xray',
        'htci',
        '7ays',
        'fyzv',
        'a4jq',
        'b4uv',
        '21yr',
        '1a0x',
        'edgc',
        '26vr',
      ],
      lastUpdated: '2024-12-20T20:38:08.000Z',
    },
  ],
  [
    'rahul.relekar@talostrading.com',
    {
      name: 'Rahul',
      badgeIDs: [
        'eaze',
        'fk3y',
        'ziyg',
        'rtdk',
        't61t',
        'x7tl',
        'ifu1',
        'gm0p',
        '1tgz',
        'cxaa',
        'nwqw',
        'b4uv',
        '21yr',
        'rt3p',
      ],
      lastUpdated: '2024-12-31T10:48:53.000Z',
    },
  ],
  [
    'andrew.matuza@talostrading.com',
    {
      name: 'Tuza',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'ziyg',
        '56jo',
        'krih',
        'rw0q',
        'rtdk',
        'nnm0',
        'y0az',
        'x7tl',
        'ifu1',
        'gm0p',
        'hl4y',
        'iqzd',
        'n0w4',
        '7y79',
        'ao3c',
        'gu53',
        '1tgz',
        'hoeg',
        '9x0m',
        'jjqu',
        'snp0',
        'cxaa',
        'nwqw',
        'tnlq',
        'a4jq',
        'sy7t',
        '86xv',
        'b4uv',
        '21yr',
        'q6ss',
        'jdoi',
        'rt3p',
        'ixey',
        'p12z',
        '1a0x',
        'gcr2',
        'c9da',
        '65ry',
        'tk16',
        'r2v8',
        '1mew',
        '8nll',
        '1m01',
        '20nu',
        'uv79',
        'ayyc',
        'ibdm',
      ],
      lastUpdated: '2025-01-02T21:55:26.000Z',
    },
  ],
  [
    'kaan.giray@talostrading.com',
    {
      name: 'k-dog',
      badgeIDs: ['eaze', 'fk3y', 'zprr', 't61t', 'x7tl', 'ifu1', 'gm0p', 'n0w4', 'nmyu', 'c3gi', 't4lx'],
      lastUpdated: '2025-01-08T13:32:36.000Z',
    },
  ],
  [
    'doros@talostrading.com',
    {
      name: 'Doros',
      badgeIDs: [
        'eaze',
        'tpbo',
        'jt0d',
        'dr56',
        'fk3y',
        'ziyg',
        'zprr',
        'rw0q',
        'rtdk',
        'x7tl',
        'gm0p',
        'hl4y',
        'iqzd',
        'n0w4',
        'iuvn',
        '7y79',
        'ao3c',
        '1tgz',
        'hoeg',
        '9x0m',
        'xray',
        'htci',
        'cxaa',
        'nwqw',
        '86xv',
        '21yr',
        'n26f',
        'gu8b',
        'q6ss',
        'jdoi',
        '0lkm',
        'ixey',
        'p12z',
        '1a0x',
        'gcr2',
        'edgc',
        'nogh',
        'uv79',
        'ayyc',
      ],
      lastUpdated: '2025-01-09T09:55:21.000Z',
    },
  ],
]);
