import React, { SyntheticEvent, useState } from 'react';
import './BadgeComponent.css';
import { Badge } from '../hooks/useBadges.ts';

const placeholderUrl = 'https://via.placeholder.com/256';

interface BadgeComponentProps {
  badge: Badge;
  onClick: (badge: Badge) => void;
  highlight?: boolean | null;
  display?: boolean;
  showName?: boolean;
  showDescription?: boolean;
  useDelay?: boolean;
  enableInteraction?: boolean;
  blackAndWhite?: boolean;
  smaller?: boolean;
  showFloatingNameShadow?: boolean;
  lowOpacity?: boolean;
  showNameHeight?: boolean;
  hiddenName?: boolean;
  ideaIncluded?: boolean;
  designIncluded?: boolean;
  initiallyHidden?: boolean;
  isEditing?: boolean;
  cursorNotAllowed?: boolean;
}

export const BadgeComponent = ({
  badge,
  onClick,
  highlight,
  display,
  enableInteraction = true,
  showName = enableInteraction,
  showDescription = enableInteraction,
  blackAndWhite = !enableInteraction,
  useDelay = false,
  smaller,
  showFloatingNameShadow = true,
  lowOpacity = false,
  showNameHeight = showName,
  hiddenName = false,
  designIncluded = false,
  ideaIncluded = false,
  initiallyHidden = false,
  isEditing = false,
  cursorNotAllowed = false,
}: BadgeComponentProps) => {
  const [hovered, setHovered] = useState(false);
  const delayString = useDelay ? `${Math.floor(Math.random() * 500) + 500}ms` : `0ms`;

  return (
    <div
      className={`badge rarity-${badge.rarityName} fadeIn ${smaller ? 'smaller border-radius' : ''}`}
      style={{ '--delay': delayString } as React.CSSProperties}
      onClick={() => enableInteraction && !cursorNotAllowed && onClick(badge)}
      onMouseEnter={() => {
        if (showDescription && enableInteraction) {
          setHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (showDescription) {
          setHovered(false);
        }
      }}
    >
      <div className={`badge-inner ${initiallyHidden && !hiddenName && 'hidden-name-removed'} ${badge.rarityName}`}>
        {enableInteraction && (
          <img
            className={`badge-rarity glow-effect-${badge.rarityName}`}
            src={badge.rarityImageUrl}
            alt={badge.rarityName}
          />
        )}
        <img
          className={`badge-image 
          ${smaller ? 'smaller' : ''}
          ${!enableInteraction && 'disable-interaction'} 
          ${highlight && 'highlight'} 
          ${blackAndWhite && 'black-and-white'}
          ${!enableInteraction ? (blackAndWhite ? 'opacity-lower-transition' : 'opacity-higher-transition') : ''}
          ${lowOpacity && 'low-opacity'}
          ${isEditing && badge.banSelfClaim && 'ban-self-claim'}
          ${cursorNotAllowed && 'cursor-not-allowed'}
          ${display && 'display'}`}
          src={badge.smallImageUrl}
          onError={(e: SyntheticEvent<HTMLImageElement>) => (e.currentTarget.src = placeholderUrl)}
          alt={badge.name}
        />
      </div>
      {hovered && showDescription && (
        <div className="floating-description">
          <p>{badge.description}</p>
        </div>
      )}
      {(showName || showNameHeight) && (
        <p
          className="floating-name"
          style={{
            background: showFloatingNameShadow
              ? `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0))`
              : 'transparent',
          }}
        >
          {ideaIncluded ? '✏️ ' : ''}
          {/* {ideaIncluded && !designIncluded ? ' ' : ''} */}
          {designIncluded && !ideaIncluded ? '🎨 ' : ''}
          {showName ? badge.name : ''}
          {hiddenName ? '🔒' : ''}
        </p>
      )}
    </div>
  );
};
