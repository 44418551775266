import React, { useEffect, SyntheticEvent } from 'react';
import './Modal.css';
import { JOURNEYS } from '../utils/journeys.ts';
import { BadgeModes } from '../containers/MainButtonsContainer.tsx';
import { Profile } from '../hooks/useProfiles.ts';
import { Badge } from '../hooks/useBadges.ts';

const placeholderUrl = 'https://via.placeholder.com/256';

export const getBadgeRarityName = ({ rarityName }: { rarityName: string }) => {
  const rarityScore = rarityName === 'common' ? 1 : rarityName === 'rare' ? 2 : rarityName === 'legendary' ? 4 : 8;
  return `${rarityName.charAt(0).toUpperCase() + rarityName.slice(1)} (${rarityScore} point${
    rarityScore === 1 ? '' : 's'
  })`;
};

interface ModalProps {
  badge: Badge;
  onClose: (openSubmitted: boolean) => void;
  profilesMap: Map<string, Profile>;
  badgesMap: Map<string, Badge>;
  setSelectedProfileID: (profileID: string) => void;
  setMode: (mode: BadgeModes) => void;
  selectedProfileID: string | null;
  mode: BadgeModes;
}

const Modal: React.FC<ModalProps> = ({
  badge,
  onClose,
  profilesMap,
  badgesMap,
  setSelectedProfileID,
  selectedProfileID,
  setMode,
  mode,
}) => {
  const [displaySource, setDisplaySource] = React.useState(badge.smallImageUrl);
  const [loadedCount, setLoadedCount] = React.useState(0);
  const profileCount = profilesMap.size;

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.keyCode === 27) onClose(false); // keyCode 27 is the escape key
    }

    window.addEventListener('keydown', handleEscapeKey);

    return () => window.removeEventListener('keydown', handleEscapeKey);
  }, [onClose]);

  const isConsideredMobile = window.innerWidth < 615;

  const creditsTextFirstPart = `✏️ ${!badge.design ? '& 🎨 ' : ''}${badge.credits}`;
  const creditsTextSecondPart = badge.design ? `🎨 ${badge.design}` : null;

  const CreditsI = ({ part }: { part: string | null }) => {
    const emailFromPart = part?.split(' ').at(-1);

    const handleOnClick = () => {
      if (emailFromPart && profilesMap.has(emailFromPart)) {
        setSelectedProfileID(emailFromPart);
        onClose(true);
      }
    };

    return (
      <i onClick={handleOnClick} style={{ cursor: 'pointer' }}>
        {part}
      </i>
    );
  };

  const journeysThatAppear = JOURNEYS.filter((journey) =>
    journey.path.some((path) => path.badgeAnds.includes(badge.id) || path.badgeOrs.includes(badge.id))
  );

  return (
    <div className="modal-overlay" onClick={() => onClose(false)}>
      <div className={isConsideredMobile ? `modal-content modal-content-mobile` : `modal-content`}>
        <div className="main-and-journeys">
          <div className="main-content" onClick={(e: SyntheticEvent) => e.stopPropagation()}>
            <img
              onLoad={() => {
                if (loadedCount === 0) {
                  setTimeout(() => {
                    setDisplaySource(badge.imageUrl);
                  }, 250);
                }
                setLoadedCount((prev) => prev + 1);
              }}
              src={displaySource}
              alt={badge.name}
              className="modal-image"
              onError={(e: SyntheticEvent<HTMLImageElement>) => (e.currentTarget.src = placeholderUrl)}
            />
            {loadedCount < 2 && (
              <div style={{ marginBottom: '8px', fontSize: '0.7em', position: 'relative' }}>
                <i
                  style={{
                    position: 'absolute',
                    top: '-2rem',
                    left: '0.3rem',
                    color: 'white',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                  }}
                >
                  Loading full-size resource...
                </i>
              </div>
            )}
            <div className="h-stack emphasized-title" style={{ justifyContent: 'center', alignItems: 'center' }}>
              <img
                src={badge.rarityImageUrl}
                alt={badge.rarityName}
                className="drop-badge-rarity-shadow"
                style={{ height: '1.4rem', width: '1.4rem' }}
                title={getBadgeRarityName(badge)}
              />
              <h3>{badge.name}</h3>
            </div>
            <p style={{ textAlign: 'center', marginBottom: '0.3rem' }}>{badge.description}</p>
            <div style={{ marginBottom: creditsTextSecondPart ? '0.1rem' : '' }}>
              <CreditsI part={creditsTextFirstPart} />
            </div>
            <div>
              <CreditsI part={creditsTextSecondPart} />
            </div>
          </div>
          {journeysThatAppear.length > 0 && (
            <div>
              <p className="journeys-included-title">
                <i>{badge.name}</i> {isConsideredMobile ? 'appears in:' : 'is part of the following journeys:'}
              </p>
              <div className="journeys-included">
                {journeysThatAppear.map((journey) => (
                  <div
                    className="journey-included"
                    key={journey.id}
                    onClick={(e: SyntheticEvent) => {
                      e.stopPropagation();
                      const firstProfileEmail: string | undefined =
                        selectedProfileID && profilesMap.get(selectedProfileID)?.badgesIDs.includes(badge.id)
                          ? selectedProfileID
                          : profilesMap.get(badge.participantIDsWhoOwnThisBadge.at(0) ?? '')?.email;
                      if (firstProfileEmail) {
                        setSelectedProfileID(firstProfileEmail);
                        setMode('journeys');
                        onClose(false);
                      }
                    }}
                  >
                    <img
                      src={journey.rarityImageUrl}
                      alt={journey.rarityName}
                      className="journey-badge-rarity-small drop-badge-rarity-shadow"
                      title={getBadgeRarityName(journey)}
                    />
                    <p className="journey-name">{journey.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* TODO TEMPORARY */}
        {!isConsideredMobile && badge.participantIDsWhoOwnThisBadge?.length > 0 && (
          <div className={isConsideredMobile ? 'badge-owners-container-mobile' : 'badge-owners-container'}>
            <div className={`pill glow-${badge.rarityName}`} onClick={(e: SyntheticEvent) => e.stopPropagation()}>
              Claimed by {badge.participantIDsWhoOwnThisBadge.length} profile
              {badge.participantIDsWhoOwnThisBadge.length === 1 ? '' : 's'} (
              {Math.round((badge.participantIDsWhoOwnThisBadge.length / profileCount) * 100)}%)
            </div>
            {badge.participantIDsWhoOwnThisBadge.map((email, index) => {
              const profile = profilesMap.get(email) as Profile;
              return (
                <div
                  className="participant-card"
                  key={index}
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    setSelectedProfileID(email);
                    setMode('collected');
                    onClose(false);
                  }}
                >
                  <img
                    src={
                      badgesMap.get(
                        profilesMap
                          .get(email)
                          ?.badgesIDs.sort((a, b) => {
                            const aBadge = badgesMap.get(a);
                            const bBadge = badgesMap.get(b);
                            if (aBadge == null || bBadge == null) return 0;
                            return (
                              aBadge.participantIDsWhoOwnThisBadge.length / aBadge.rarityScore -
                              bBadge.participantIDsWhoOwnThisBadge.length / bBadge.rarityScore
                            );
                          })
                          .at(0) ?? ''
                      )?.smallImageUrl
                    }
                    alt={badge.name}
                    className="participant-image"
                  />
                  <div style={{ textAlign: 'start' }}>
                    <h3 className="profile-header">
                      <span className="profile-name">{profile.name}</span>
                    </h3>
                    <p className="participant-badges">
                      Collected {profile.badgesIDs.length}/{badgesMap.size} badges
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
