import React, { useEffect } from 'react';
import './JourneysContainer.css';
import { JourneyComponent } from '../components/JourneyComponent.tsx';
import { JOURNEYS } from '../utils/journeys.ts';
import { Badge } from '../hooks/useBadges.ts';
import { Profile } from '../hooks/useProfiles.ts';

interface JourneysContainerProps {
  badgesMap: Map<string, Badge>;
  selectedProfile: Profile | null;
  profilesMap: Map<string, Profile>;
  onClickBadge: (badge: Badge) => void;
}

export const JourneysContainer = ({ badgesMap, selectedProfile, onClickBadge }: JourneysContainerProps) => {
  const [selectedProfilesBadgeIDs, setSelectedProfilesBadgeIDs] = React.useState<string[]>([]);
  useEffect(() => {
    setSelectedProfilesBadgeIDs(selectedProfile?.badgesIDs ?? []);
  }, [selectedProfile]);

  return (
    <div className="collected-badges-container">
      <div className="collected-badges-inner-container">
        <div className="journeys-container">
          {JOURNEYS.map((journey) => {
            return (
              <JourneyComponent
                key={journey.name}
                journey={journey}
                badgesMap={badgesMap}
                badgeIDs={selectedProfilesBadgeIDs}
                onClickBadge={(badge) => onClickBadge(badge)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
