import { useEffect, useState } from 'react';
import { BADGE_DESCRIPTIONS, BadgeDescription } from '../utils/badge-descriptions.ts';

/** Final shape of each Badge.
 *  - "credits" or "design" can each exist, or just one of them, depending on your data.
 */
export interface Badge {
  id: string;
  name: string;
  description: string;
  credits?: string;
  design?: string;
  participantIDsWhoOwnThisBadge: string[];

  /** Rarity info */
  rarityName: 'common' | 'rare' | 'legendary' | 'mythic';
  rarityScore: 1 | 2 | 4 | 8;

  /** Possible images */
  imageUrl: string;
  smallImageUrl: string;
  IDImageUrl: string;
  smallIDImageUrl: string;
  rarityImageUrl: string;

  /** Fields that getAutoUpdatedMap might need */
  autoClaimRules?: { ids: string[]; count: number };
  banSelfClaim?: boolean;
  restrictedRules?: { ids: string[]; count: number };
}

/**
 * Loads all badges from BADGE_DESCRIPTIONS into a Map<string, Badge>,
 * preserving credits/design fields, and any auto-claim/restricted rules.
 */
export function useBadges() {
  const [badgesMap, setBadgesMap] = useState<Map<string, Badge>>(new Map());

  useEffect(() => {
    const loadBadges = async () => {
      const map = new Map<string, Badge>();

      BADGE_DESCRIPTIONS.forEach((desc: BadgeDescription, badgeId: string) => {
        const badgeImageName = desc.name.replace(/\s+/g, '_') + '.png';

        // Convert numeric rarityScore -> text label
        let rarityName: 'common' | 'rare' | 'legendary' | 'mythic' = 'common';
        if (desc.rarityScore === 2) rarityName = 'rare';
        if (desc.rarityScore === 4) rarityName = 'legendary';
        if (desc.rarityScore === 8) rarityName = 'mythic';

        map.set(badgeId, {
          id: badgeId,
          name: desc.name,
          description: desc.description,
          credits: desc.credits,
          design: desc.design,

          rarityScore: desc.rarityScore,
          rarityName,
          imageUrl: `${process.env.PUBLIC_URL}/images/${badgeImageName}`,
          smallImageUrl: `${process.env.PUBLIC_URL}/images/resized-images/${badgeImageName}`,
          IDImageUrl: `${process.env.PUBLIC_URL}/images/${badgeId}.png`,
          smallIDImageUrl: `${process.env.PUBLIC_URL}/images/resized-images/${badgeId}.png`,
          rarityImageUrl: `${process.env.PUBLIC_URL}/images/${rarityName}.png`,

          // Pass along any rules
          autoClaimRules: desc.autoClaimRules,
          banSelfClaim: desc.banSelfClaim,
          restrictedRules: desc.restrictedRules,
          participantIDsWhoOwnThisBadge: [],
        });
      });

      setBadgesMap(map);
    };

    loadBadges();
  }, []);

  return { badgesMap, setBadgesMap };
}
