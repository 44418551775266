import React from 'react';
import { BadgeComponent } from './BadgeComponent.tsx';
import './JourneyComponent.css';
import { isJourneyCompleted, Journey } from '../utils/journeys.ts';
import { Badge } from '../hooks/useBadges.ts';

export const JourneyComponent = ({
  journey,
  badgesMap,
  badgeIDs,
  onClickBadge,
}: {
  journey: Journey;
  badgesMap: Map<string, Badge>;
  badgeIDs: string[];
  onClickBadge: (badge: Badge) => void;
}) => {
  return (
    <div className={`journey ${isJourneyCompleted(journey, badgeIDs) ? `completed` : ''} ${journey.id}`}>
      <div className="journey-badges">
        <div style={{ height: '2rem', display: 'flex', alignItems: 'center' }}>
          <img
            className={`journey-badge-rarity glow-effect-${journey.rarityName}`}
            src={journey.rarityImageUrl}
            alt={journey.rarityName}
          />
          {isJourneyCompleted(journey, badgeIDs) ? <p className="journey-completed-emoji">{journey.emoji}</p> : null}
        </div>
        <h3 className="journey-name" style={{ marginBottom: '0.4rem' }}>
          {journey.name}
        </h3>
        {journey.path.map((pathStep, index) => (
          <div className="journey-badges-step" key={index}>
            {pathStep.badgeAnds.map((badgeID, index) => (
              <div className="journey-badges-step-inner" key={index}>
                {index > 0 && <span className="badge-connector">AND</span>}
                <BadgeComponent
                  key={badgeID}
                  badge={badgesMap.get(badgeID)!}
                  onClick={onClickBadge}
                  smaller
                  display
                  blackAndWhite={!badgeIDs.includes(badgeID)}
                />
              </div>
            ))}
            {pathStep.badgeAnds.length > 0 && pathStep.badgeOrs.length > 0 && (
              <span className="badge-connector">AND</span>
            )}
            {pathStep.badgeOrs
              .filter((badgeID) => badgesMap.has(badgeID))
              .map((badgeID, index) => (
                <div className="journey-badges-step" key={index}>
                  {index > 0 && <span className="badge-connector">OR</span>}
                  <BadgeComponent
                    key={badgeID}
                    badge={badgesMap.get(badgeID)!}
                    onClick={onClickBadge}
                    smaller
                    display
                    blackAndWhite={!badgeIDs.includes(badgeID)}
                    lowOpacity={!badgeIDs.includes(badgeID)}
                  />
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};
