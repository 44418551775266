import { Profile } from './useProfiles';

/**
 * Keeps your original auto-claim and restricted logic.
 * Adjust or remove the "uwpa"/"65ry" checks as needed.
 */
export function getAutoUpdatedMap(
  enabledBadgesMap: Map<string, boolean>,
  badgesMap: Map<
    string,
    {
      id: string;
      autoClaimRules?: { ids: string[]; count: number };
      banSelfClaim?: boolean;
      restrictedRules?: { ids: string[]; count: number };
      name: string;
    }
  >,
  selectedProfile: Pick<Profile, 'submittedBadgeIDs' | 'email'>
): Map<string, boolean> {
  const newEnabledBadgesMap = new Map(enabledBadgesMap);
  const submittedBadgesCount = selectedProfile.submittedBadgeIDs.length;

  for (const badge of badgesMap.values()) {
    const { id, autoClaimRules, banSelfClaim, restrictedRules } = badge;

    // Enable if auto-claim conditions are satisfied
    if (
      autoClaimRules &&
      autoClaimRules.ids.filter((depId) => newEnabledBadgesMap.get(depId)).length >= autoClaimRules.count
    ) {
      newEnabledBadgesMap.set(id, true);
    }
    // Otherwise disable if we have a banSelfClaim or if restrictedRules fail
    else if (
      (autoClaimRules && banSelfClaim) ||
      (restrictedRules &&
        restrictedRules.ids.filter((depId) => newEnabledBadgesMap.get(depId)).length < restrictedRules.count)
    ) {
      newEnabledBadgesMap.set(id, false);
    }

    if (!newEnabledBadgesMap.has(badge.id)) {
      newEnabledBadgesMap.set(badge.id, false);
    }
  }

  // Example: auto-enable certain badges if user has submitted at least N badges
  if (submittedBadgesCount >= 10) {
    newEnabledBadgesMap.set('uwpa', true);
  } else {
    newEnabledBadgesMap.set('uwpa', false);
  }

  if (submittedBadgesCount >= 1) {
    newEnabledBadgesMap.set('65ry', true);
  } else {
    newEnabledBadgesMap.set('65ry', false);
  }

  // Pixel Prestige is awarded manually.
  if (selectedProfile.email === 'andrew.matuza@talostrading.com') {
    newEnabledBadgesMap.set('20nu', true);
  } else {
    newEnabledBadgesMap.set('20nu', false);
  }

  return newEnabledBadgesMap;
}
