import React, { useState, useEffect } from 'react';
import './BadgesBackgroundScrollContainer.css';
import Modal from '../components/Modal.tsx';
import { BadgeComponent } from '../components/BadgeComponent.tsx';
import { BadgeModes } from './MainButtonsContainer.tsx';
import { Badge } from '../hooks/useBadges.ts';
import { Profile } from '../hooks/useProfiles.ts';

interface BadgesBackgroundScrollContainerProps {
  badgesMap: Map<string, Badge>;
  profilesMap: Map<string, Profile>;
  setSelectedProfileID: (profileID: string | null) => void;
  selectedBadge: Badge | null;
  setSelectedBadge: (badge: Badge | null) => void;
  enableInteraction: boolean;
  ghostProfileID: string | null;
  mode: BadgeModes;
  setMode: (mode: BadgeModes) => void;
  selectedProfileID: string | null;
}

export const BadgesBackgroundScrollContainer = ({
  badgesMap,
  profilesMap,
  setSelectedProfileID,
  selectedBadge,
  setSelectedBadge,
  enableInteraction,
  ghostProfileID,
  mode,
  setMode,
  selectedProfileID,
}: BadgesBackgroundScrollContainerProps) => {
  function shuffleArray(array: string[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const [arraysOfBadgeArrays, setArraysOfBadgeArrays] = useState<string[][]>([]);

  useEffect(() => {
    const numberOfRows = 5;
    const badgeIDs = shuffleArray(Array.from(badgesMap.keys()));
    const resultArr: string[][] = [];

    const sliceLength = Math.floor(badgeIDs.length / numberOfRows);
    for (let i = 0; i < numberOfRows; i++) {
      resultArr.push(badgeIDs.slice(sliceLength * i, sliceLength * i + sliceLength));
    }
    setArraysOfBadgeArrays(resultArr);
  }, [badgesMap]);

  return (
    <div className="all-badges-container">
      {arraysOfBadgeArrays.map((badgesArray, index) => (
        <div key={index} className={`${enableInteraction && 'enable-interaction'} badges-container delay-${index}`}>
          {badgesArray.map((badgeID) => (
            <BadgeComponent
              enableInteraction={enableInteraction}
              key={badgeID}
              badge={badgesMap.get(badgeID)!}
              onClick={(badge) => setSelectedBadge(badge)}
              useDelay
              showName={false}
              showDescription={false}
              blackAndWhite={
                mode === 'editingprofile' ||
                mode === 'submitted' ||
                (ghostProfileID ? !profilesMap.get(ghostProfileID ?? '')?.badgesIDs.includes(badgeID) : undefined)
              }
            />
          ))}
        </div>
      ))}
      {selectedBadge && (
        <Modal
          badge={selectedBadge}
          onClose={(openSubmitted: boolean) => {
            setSelectedBadge(null);
            if (openSubmitted) {
              setMode('submitted');
            }
          }}
          badgesMap={badgesMap}
          profilesMap={profilesMap}
          setSelectedProfileID={setSelectedProfileID}
          mode={mode}
          setMode={setMode}
          selectedProfileID={selectedProfileID}
        />
      )}
    </div>
  );
};
