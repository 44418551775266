import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './App.css';

import { BadgeModes, MainButtonsContainer } from './containers/MainButtonsContainer.tsx';
import { BadgesBackgroundScrollContainer } from './containers/BadgesBackgroundScrollContainer.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, useBadges } from './hooks/useBadges.ts';
import { useProfiles } from './hooks/useProfiles.ts';

export const App = () => {
  const { badgesMap, setBadgesMap } = useBadges();
  const { profilesMap } = useProfiles(badgesMap);
  const [didUpdateBadges, setDidUpdateBadges] = useState<boolean>(false);

  // when profilesMap is ready, lets update badgesMap with the participantIDsWhoOwnThisBadge
  useEffect(() => {
    if (profilesMap.size > 0 && didUpdateBadges === false) {
      const updatedBadgesMap = new Map<string, Badge>();

      // if a badge is owned by a participant, add the participantID to the badge
      for (const [badgeID, badge] of badgesMap) {
        const badgeCopy = { ...badge };
        const participantIDsWhoOwnThisBadge = new Set<string>();
        for (const [profileID, profile] of profilesMap) {
          if (profile.badgesIDs.includes(badgeID)) {
            participantIDsWhoOwnThisBadge.add(profileID);
          }
        }
        badgeCopy.participantIDsWhoOwnThisBadge = Array.from(participantIDsWhoOwnThisBadge);
        updatedBadgesMap.set(badgeID, badgeCopy);
      }

      setBadgesMap(updatedBadgesMap);
      setDidUpdateBadges(true);
    }
  }, [badgesMap, didUpdateBadges, profilesMap, setBadgesMap]);

  const [selectedProfileID, setSelectedProfileID] = useState<string | null>(null);
  const [selectedBadge, setSelectedBadge] = useState<Badge | null>(null);
  const [ghostProfileID, setGhostProfileID] = useState<string | null>(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (selectedProfileID != null) {
      return;
    }
    const name = location.pathname.split('/')[1];
    if (name && profilesMap.size > 0) {
      const email = `${name}@talostrading.com`;
      if (profilesMap.has(email)) {
        setSelectedProfileID(email);
        setGhostProfileID(email);
        setMode('collected');
      } else {
        setSelectedProfileID(null);
        setGhostProfileID(null);
        setMode('splash');
        // Navigate to the home page if the profile is not found
        window.location.href = '/';
      }
    }
  }, [location, profilesMap, selectedProfileID]);

  const shouldShowLoading = useMemo(() => {
    return badgesMap.size === 0 || profilesMap.size === 0;
  }, [badgesMap.size, profilesMap.size]);

  const handleSetProfileID = useCallback(
    (profileID: string | null) => {
      setSelectedProfileID(profileID);
      setGhostProfileID(profileID);
      const emailBeforeAt = profileID?.split('@')[0];
      if (profileID == null) {
        navigate('/');
      } else {
        navigate(`/${emailBeforeAt}`);
      }
    },
    [navigate]
  );

  const [mode, setMode] = useState<BadgeModes>('splash');

  if (shouldShowLoading) {
    return (
      <center className="loading">
        {badgesMap.size === 0 ? <p>Loading Badges</p> : profilesMap.size === 0 ? <p>Loading Profiles</p> : null}
        <img src="favicon.ico" alt="loading" className="spinning" width="20rem" height="20rem" />
      </center>
    );
  }

  if (window.innerWidth < 350) {
    return <center className="loading">Screen width too small.</center>;
  }

  return (
    <div className="App">
      <center className={`loading ${imagesLoaded && 'done'}`}>
        <p>Loading Images</p>
        <img src="favicon.ico" alt="loading" className="spinning" width="20rem" height="20rem" />
      </center>
      <div className={!imagesLoaded ? 'hide' : ''}>
        <BadgesBackgroundScrollContainer
          selectedBadge={selectedBadge}
          setSelectedBadge={setSelectedBadge}
          badgesMap={badgesMap}
          profilesMap={profilesMap}
          ghostProfileID={ghostProfileID}
          setSelectedProfileID={handleSetProfileID}
          enableInteraction={selectedProfileID == null && mode !== 'badgegallery'}
          mode={mode}
          setMode={setMode}
          selectedProfileID={selectedProfileID}
        />
        <MainButtonsContainer
          profilesMap={profilesMap}
          badgesMap={badgesMap}
          selectedProfileID={selectedProfileID}
          setSelectedProfileID={handleSetProfileID}
          selectedBadge={selectedBadge}
          setSelectedBadge={setSelectedBadge}
          setGhostProfileID={setGhostProfileID}
          setImagesLoaded={setImagesLoaded}
          mode={mode}
          setMode={setMode}
        />
      </div>
    </div>
  );
};
